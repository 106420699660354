<template>
    <section>
        <b-card class="mb-2">
            <header>
                <h4 class="text-center">
                    <strong>
                        Laporan Tryout {{ paket?.name }}
                    </strong>
                </h4>
            </header>
            <hr>
            <section>
                <table class="w-100">
                    <tr>
                        <td>Nama</td>
                        <th>:</th>
                        <th>
                            <span v-if="siswa">{{ siswa.name }}</span>
                            <i class="text-danger" v-else>
                                Nama siswa tidak ditemukan
                            </i>
                        </th>
                    </tr>
                    <tr>
                        <td>Asal Sekolah</td>
                        <th>:</th>
                        <th>
                            <span v-if="siswa && siswa.detail && siswa.detail.school_origin_info">
                                {{ siswa.detail.school_origin_info.nama_sekolah }}
                            </span>
                            <i class="text-danger" v-else>
                                Sekolah siswa tidak ditemukan
                            </i>
                        </th>
                    </tr>
                    <tr v-if="jadwal">
                        <td>Info Jadwal</td>
                        <th>:</th>
                        <th>
                            {{jadwal.name}} -> {{ jadwal.start_time }} s/d {{ jadwal.end_time }}
                        </th>
                    </tr>
                </table>
            </section>
        </b-card>

        <b-card-actions
            ref="nilai-tes-card"
            title="Data Benar & Salah Siswa"
            action-collapse
            v-if="result && result.nilai_sub_categories"
        >
            <header>
                <b-table-simple responsive bordered striped hover>
                    <b-tr>
                        <b-th></b-th>
                        <b-th>Total Soal</b-th>
                        <b-th>Jumlah Benar</b-th>
                        <b-th>Jumlah Salah</b-th>
                        <b-th>Jumlah Kosong</b-th>
                        <b-th>Nilai</b-th>
                        <b-th>Total %</b-th>
                    </b-tr>
                    <b-tr
                        v-for="(subcat, i) in result.nilai_sub_categories"
                        :key="i"
                    >
                        <b-th>
                            {{ subcat.name }}
                        </b-th>
                        <b-td>
                            {{ subcat.total_soal }}
                        </b-td>
                        <b-td>
                            {{ subcat.jumlah_benar }}
                        </b-td>
                        <b-td>
                            {{ subcat.jumlah_salah }}
                        </b-td>
                        <b-td>
                            {{ subcat.jumlah_kosong }}
                        </b-td>
                        <b-td>
                            {{ subcat.nilai }}
                        </b-td>
                        <b-td rowspan="2">{{ getAverage(result.nilai_sub_categories) }}</b-td>
                    </b-tr>
                </b-table-simple>
            </header>
        </b-card-actions>
        <b-card-actions
            ref="hasil-card"
            title="Rasionalisasi Nilai UTBK"
            action-collapse
        >
            <b-table-simple responsive>
                <b-tr>
                    <b-th class="text-center" rowspan="2">SKOR TO</b-th>
                    <b-th class="text-center" colspan="2">PTN DAN JURUSAN PILIHAN</b-th>
                    <b-th class="text-center" rowspan="2">PG KELULUSAN</b-th>
                    <b-th class="text-center" rowspan="2">PELUANG KELULUSAN</b-th>
                </b-tr>
                <b-tr>
                    <b-th class="text-center">PTN</b-th>
                    <b-th class="text-center">PRODI</b-th>
                </b-tr>

                <b-tr
                    v-for="(item, i) in result.selected_majors"
                    :key="i"
                >
                    <b-td class="text-center">{{ item.nilai_akhir }}</b-td>
                    <b-td class="text-center">{{ item.school?.name }}</b-td>
                    <b-td class="text-center">{{ item.name }}</b-td>
                    <b-td class="text-center">{{ item.passing_grade_percentage }}</b-td>
                    <b-td class="text-center">
                        <strong :class="item.is_passed ? 'text-success' : 'text-danger'">
                            {{ item.is_passed ? 'LULUS' : 'TIDAK LULUS' }}
                        </strong>
                    </b-td>
                </b-tr>
            </b-table-simple>
        </b-card-actions>
    </section>
</template>
<script>
import {BRow, BCol, BCard, BTableSimple, BTr, BTh, BTd} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
export default {
    props: {
        result: {
            type: Object,
            require: true
        }
    },
    components: {
        BRow, BCol, BCard, BTableSimple, BTr, BTh, BTd, BCardActions
    },
    computed: {
        paket() {
            return this.$store.state.rasionalisasi.currentPaket
        },
        isPaketContainSubcat() {
            return this.paket && this.paket.is_contain_subcat == 1
        },
        jadwal() {
            return this.$store.state.rasionalisasi.currentJadwal
        },
        siswa() {
            return this.$store.state.rasionalisasi.currentSiswa
        },
    },
    data: () => ({
        subtests: []
    }),
    methods: {
        getAverage(nilaiSubCat) {
            const sumNilai = nilaiSubCat.reduce((total, item) => total += item.nilai,0)
            const countNilai = nilaiSubCat.length

            return sumNilai / countNilai
        },
        async getSubcat(paketId) {
            try {
                const params = {
                    paket_id: paketId
                }

                const response = await this.$store.dispatch('paketTryout/getSubcat', params)
                let subcats = response.data

                // add subcat to result rasionalisasi
                if(this.result) {
                    const nilaiSubtests = this.result.nilai_subtests
                    subcats.map(subcat => {
                        subcat.items = nilaiSubtests.filter(subtest => subtest.subcat_id == subcat.id)

                        return subcat
                    })
                    .forEach((item, i) => {
                        this.subtests.push({
                            id: item.id,
                            name: item.name,
                            parent: true,
                            result: null
                        })

                        item.items.forEach((subtest) => {
                            this.subtests.push({
                                id: subtest.subtest_id,
                                name: subtest.ujian_name,
                                parent: false,
                                result: {
                                    bobot_nilai: subtest.bobot_nilai,
                                    bobot_nilai_to: subtest.bobot_nilai_to,
                                    nilai_to: subtest.nilai_to
                                }
                            })
                        })
                    })
                }
            }
            catch(e) {
                this.displayError(e)
                return false
            }
        }
    }
}
</script>