<template>
    <section>
        <b-card class="mb-2">
            <header>
                <h4 class="text-center">
                    <strong>
                        Laporan Tryout {{ paket?.name }}
                    </strong>
                </h4>
            </header>
            <hr>
            <section>
                <table class="w-100">
                    <tr>
                        <td>Nama</td>
                        <th>:</th>
                        <th>
                            <span v-if="siswa">{{ siswa.name }}</span>
                            <i class="text-danger" v-else>
                                Nama siswa tidak ditemukan
                            </i>
                        </th>
                    </tr>
                    <tr>
                        <td>Asal Sekolah</td>
                        <th>:</th>
                        <th>
                            <span v-if="siswa && siswa.detail && siswa.detail.school_origin_info">
                                {{ siswa.detail.school_origin_info.nama_sekolah }}
                            </span>
                            <i class="text-danger" v-else>
                                Sekolah siswa tidak ditemukan
                            </i>
                        </th>
                    </tr>
                    <tr v-if="jadwal">
                        <td>Info Jadwal</td>
                        <th>:</th>
                        <th>
                            {{jadwal.name}} -> {{ jadwal.start_time }} s/d {{ jadwal.end_time }}
                        </th>
                    </tr>
                </table>
            </section>
        </b-card>

        <b-card-actions
            ref="nilai-tes-card"
            title="Nilai Tes"
            action-collapse
        >
            <header>
                <b-table-simple v-if="isPaketContainSubcat" responsive bordered striped hover>
                    <tr
                        v-for="(item, i) in subtests"
                        :key="i"
                    >
                        <b-th v-if="item.parent" colspan="2">
                            Nilai {{ item.name }}
                        </b-th>
                        <b-td v-if="!item.parent">
                            {{ item.name }}
                        </b-td>
                        <b-td v-if="!item.parent">
                            {{ item.result.nilai_to }}
                        </b-td>
                    </tr>
                </b-table-simple>
                <b-table-simple responsive bordered striped hover v-else>
                    <tr
                        v-for="(item, i) in subtests"
                        :key="i"
                    >
                        <b-td>
                            {{ item.name }}
                        </b-td>
                        <b-td>
                            {{ item.result.nilai_to }}
                        </b-td>
                    </tr>
                </b-table-simple>
            </header>
        </b-card-actions>
        <b-card-actions
            ref="hasil-card"
            title="Rasionalisasi Nilai UTBK"
            action-collapse
        >
            <b-table-simple responsive>
                <b-tr>
                    <b-th rowspan="2" class="text-center">SKOR TO</b-th>
                    <b-th colspan="2" class="text-center">PTN DAN JURUSAN PILIHAN</b-th>
                    <b-th rowspan="2" class="text-center">NILAI AMBANG BATAS</b-th>
                    <b-th rowspan="2" class="text-center">PELUANG LULUS</b-th>
                    <b-th rowspan="2" class="text-center">KEKURANGAN NILAI</b-th>
                </b-tr>
                <b-tr>
                    <b-th class="text-center">PTN</b-th>
                    <b-th class="text-center">PRODI</b-th>
                </b-tr>

                    <b-tr v-if="!result || !result.hasil_rasionalisasi">
                        <b-td colspan="5" class="text-center">
                            Hasil Rasionalisasi belum ada
                        </b-td>
                    </b-tr>
                    <b-tr
                        v-for="(item, i) in result.hasil_rasionalisasi"
                        :key="i"
                    >
                        <b-td class="text-center">
                            <p>{{ item.score_total.toFixed(2) }}</p>
                        </b-td>
                        <b-td class="text-center">
                            <span v-if="item.university_info">
                                {{ item.university_info.name }}
                            </span>
                            <i class="text-danger" v-else>Tidak ditemukan</i>
                        </b-td>
                        <b-td class="text-center">
                            <span v-if="item.university_info">
                                {{ item.university_info.major_name }}
                            </span>
                            <i class="text-danger" v-else>Tidak ditemukan</i>
                        </b-td>
                        <b-td class="text-center">
                            <p>{{ item.nilai_ambang_batas.toFixed(2) }}</p>
                        </b-td>
                        <b-td class="text-center">
                            <strong class="text-success" v-if="item.peluang_lulus">LULUS</strong>
                            <strong class="text-danger" v-else>TIDAK LULUS</strong>
                        </b-td>
                        <b-td class="text-center">
                            <p>{{ item.kekurangan_nilai.toFixed(2) }}</p>
                        </b-td>
                    </b-tr>
            </b-table-simple>
        </b-card-actions>
    </section>
</template>
<script>
import {BRow, BCol, BCard, BTableSimple, BTr, BTh, BTd} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
export default {
    props: {
        result: {
            type: Object,
            require: true
        }
    },
    components: {
        BRow, BCol, BCard, BTableSimple, BTr, BTh, BTd, BCardActions
    },
    computed: {
        paket() {
            return this.$store.state.rasionalisasi.currentPaket
        },
        isPaketContainSubcat() {
            return this.paket && this.paket.is_contain_subcat == 1
        },
        jadwal() {
            return this.$store.state.rasionalisasi.currentJadwal
        },
        siswa() {
            return this.$store.state.rasionalisasi.currentSiswa
        },
    },
    data: () => ({
        subtests: []
    }),
    methods: {
        async getSubcat(paketId) {
            try {
                const params = {
                    paket_id: paketId
                }

                const response = await this.$store.dispatch('paketTryout/getSubcat', params)
                let subcats = response.data

                // add subcat to result rasionalisasi
                if(this.result) {
                    const nilaiSubtests = this.result.nilai_subtests
                    subcats.map(subcat => {
                        subcat.items = nilaiSubtests.filter(subtest => subtest.subcat_id == subcat.id)

                        return subcat
                    })
                    .forEach((item, i) => {
                        this.subtests.push({
                            id: item.id,
                            name: item.name,
                            parent: true,
                            result: null
                        })

                        item.items.forEach((subtest) => {
                            this.subtests.push({
                                id: subtest.subtest_id,
                                name: subtest.ujian_name,
                                parent: false,
                                result: {
                                    bobot_nilai: subtest.bobot_nilai,
                                    bobot_nilai_to: subtest.bobot_nilai_to,
                                    nilai_to: subtest.nilai_to
                                }
                            })
                        })
                    })
                }
            }
            catch(e) {
                this.displayError(e)
                return false
            }
        }
    },
    mounted() {
        if(this.isPaketContainSubcat) {
            this.getSubcat(this.paket?.id)
        }
        else {
            const nilaiSubtests = this.result.nilai_subtests
            this.subtests = nilaiSubtests.map((subtest, i) => {
                return {
                    id: subtest.subtest_id,
                    name: subtest.ujian_name,
                    result: {
                        bobot_nilai: subtest.bobot_nilai,
                        bobot_nilai_to: subtest.bobot_nilai_to,
                        nilai_to: subtest.nilai_to
                    }
                }
            })
        }
    }
}
</script>