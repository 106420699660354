var render = function () {
  var _vm$paket;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('b-card', {
    staticClass: "mb-2"
  }, [_c('header', [_c('h4', {
    staticClass: "text-center"
  }, [_c('strong', [_vm._v(" Laporan Tryout " + _vm._s((_vm$paket = _vm.paket) === null || _vm$paket === void 0 ? void 0 : _vm$paket.name) + " ")])])]), _c('hr'), _c('section', [_c('table', {
    staticClass: "w-100"
  }, [_c('tr', [_c('td', [_vm._v("Nama")]), _c('th', [_vm._v(":")]), _c('th', [_vm.siswa ? _c('span', [_vm._v(_vm._s(_vm.siswa.name))]) : _c('i', {
    staticClass: "text-danger"
  }, [_vm._v(" Nama siswa tidak ditemukan ")])])]), _c('tr', [_c('td', [_vm._v("Asal Sekolah")]), _c('th', [_vm._v(":")]), _c('th', [_vm.siswa && _vm.siswa.detail && _vm.siswa.detail.school_origin_info ? _c('span', [_vm._v(" " + _vm._s(_vm.siswa.detail.school_origin_info.nama_sekolah) + " ")]) : _c('i', {
    staticClass: "text-danger"
  }, [_vm._v(" Sekolah siswa tidak ditemukan ")])])]), _vm.jadwal ? _c('tr', [_c('td', [_vm._v("Info Jadwal")]), _c('th', [_vm._v(":")]), _c('th', [_vm._v(" " + _vm._s(_vm.jadwal.name) + " -> " + _vm._s(_vm.jadwal.start_time) + " s/d " + _vm._s(_vm.jadwal.end_time) + " ")])]) : _vm._e()])])]), _c('b-card-actions', {
    ref: "nilai-tes-card",
    attrs: {
      "title": "Nilai Tes",
      "action-collapse": ""
    }
  }, [_c('header', [_vm.isPaketContainSubcat ? _c('b-table-simple', {
    attrs: {
      "responsive": "",
      "bordered": "",
      "striped": "",
      "hover": ""
    }
  }, _vm._l(_vm.subtests, function (item, i) {
    return _c('tr', {
      key: i
    }, [item.parent ? _c('b-th', {
      attrs: {
        "colspan": "2"
      }
    }, [_vm._v(" Nilai " + _vm._s(item.name) + " ")]) : _vm._e(), !item.parent ? _c('b-td', [_vm._v(" " + _vm._s(item.name) + " ")]) : _vm._e(), !item.parent ? _c('b-td', [_vm._v(" " + _vm._s(item.result.nilai_to) + " ")]) : _vm._e()], 1);
  }), 0) : _c('b-table-simple', {
    attrs: {
      "responsive": "",
      "bordered": "",
      "striped": "",
      "hover": ""
    }
  }, _vm._l(_vm.subtests, function (item, i) {
    return _c('tr', {
      key: i
    }, [_c('b-td', [_vm._v(" " + _vm._s(item.name) + " ")]), _c('b-td', [_vm._v(" " + _vm._s(item.result.nilai_to) + " ")])], 1);
  }), 0)], 1)]), _c('b-card-actions', {
    ref: "hasil-card",
    attrs: {
      "title": "Rasionalisasi Nilai UTBK",
      "action-collapse": ""
    }
  }, [_c('b-table-simple', {
    attrs: {
      "responsive": ""
    }
  }, [_c('b-tr', [_c('b-th', {
    staticClass: "text-center",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("SKOR TO")]), _c('b-th', {
    staticClass: "text-center",
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v("PTN DAN JURUSAN PILIHAN")]), _c('b-th', {
    staticClass: "text-center",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("NILAI AMBANG BATAS")]), _c('b-th', {
    staticClass: "text-center",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("PELUANG LULUS")]), _c('b-th', {
    staticClass: "text-center",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("KEKURANGAN NILAI")])], 1), _c('b-tr', [_c('b-th', {
    staticClass: "text-center"
  }, [_vm._v("PTN")]), _c('b-th', {
    staticClass: "text-center"
  }, [_vm._v("PRODI")])], 1), !_vm.result || !_vm.result.hasil_rasionalisasi ? _c('b-tr', [_c('b-td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "5"
    }
  }, [_vm._v(" Hasil Rasionalisasi belum ada ")])], 1) : _vm._e(), _vm._l(_vm.result.hasil_rasionalisasi, function (item, i) {
    return _c('b-tr', {
      key: i
    }, [_c('b-td', {
      staticClass: "text-center"
    }, [_c('p', [_vm._v(_vm._s(item.score_total.toFixed(2)))])]), _c('b-td', {
      staticClass: "text-center"
    }, [item.university_info ? _c('span', [_vm._v(" " + _vm._s(item.university_info.name) + " ")]) : _c('i', {
      staticClass: "text-danger"
    }, [_vm._v("Tidak ditemukan")])]), _c('b-td', {
      staticClass: "text-center"
    }, [item.university_info ? _c('span', [_vm._v(" " + _vm._s(item.university_info.major_name) + " ")]) : _c('i', {
      staticClass: "text-danger"
    }, [_vm._v("Tidak ditemukan")])]), _c('b-td', {
      staticClass: "text-center"
    }, [_c('p', [_vm._v(_vm._s(item.nilai_ambang_batas.toFixed(2)))])]), _c('b-td', {
      staticClass: "text-center"
    }, [item.peluang_lulus ? _c('strong', {
      staticClass: "text-success"
    }, [_vm._v("LULUS")]) : _c('strong', {
      staticClass: "text-danger"
    }, [_vm._v("TIDAK LULUS")])]), _c('b-td', {
      staticClass: "text-center"
    }, [_c('p', [_vm._v(_vm._s(item.kekurangan_nilai.toFixed(2)))])])], 1);
  })], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }